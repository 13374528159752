import enums from '@/services/helpers/enums';
import api from '@/services/helpers/http';

async function getTranslatedDomain(countryId, languageCode) {
	if (!countryId) {
		return;
	}

	return api.get('SiteUrl/GetUrlByCountryAndLang', { countryId, languageCode }).then((res) => res.data);
}

export async function handleTextboxVisibility(modelCountryId, model) {
	const schemaUpdate = {
		AttractionTextSk: { visible: false },
		AttractionTextCz: { visible: false },
		AttractionTextEn: { visible: false },
		AttractionTextPl: { visible: false },
		AttractionTextHu: { visible: false },
		AttractionTextRu: { visible: false },
		AttractionTextDe: { visible: false },
	};

	const modelUpdate = {};

	switch (modelCountryId) {
		case enums.country.CZ:
			modelUpdate.DomainUrl = await getTranslatedDomain(model.CountryId, 'CZ');
			schemaUpdate.AttractionTextCz.visible = true;
			break;
		case enums.country.EN:
			modelUpdate.DomainUrl = await getTranslatedDomain(model.CountryId, 'EN');
			schemaUpdate.AttractionTextEn.visible = true;
			break;
		case enums.country.PL:
			modelUpdate.DomainUrl = await getTranslatedDomain(model.CountryId, 'PL');
			schemaUpdate.AttractionTextPl.visible = true;
			break;
		case enums.country.HU:
			modelUpdate.DomainUrl = await getTranslatedDomain(model.CountryId, 'HU');
			schemaUpdate.AttractionTextHu.visible = true;
			break;
		case enums.country.RU:
			modelUpdate.DomainUrl = await getTranslatedDomain(model.CountryId, 'RU');
			schemaUpdate.AttractionTextRu.visible = true;
			break;
		case enums.country.DE:
			modelUpdate.DomainUrl = await getTranslatedDomain(model.CountryId, 'DE');
			schemaUpdate.AttractionTextDe.visible = true;
			break;
		case enums.country.SK:
			modelUpdate.DomainUrl = await getTranslatedDomain(model.CountryId, 'SK');
		default:
			schemaUpdate.AttractionTextSk.visible = true;
			break;
	}

	return { schemaUpdate, modelUpdate };
}
