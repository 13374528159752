<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		tooltipMsg="manualforattrac3309"
		:showActionDelete="showDelete"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openDeleteModal()"
	>
		<template #header>
			{{ $t('detailofattract') }}
			<b-link target="_blank" :href="`${model.AttractionDomainGroupUrl + model.AttractionUrlName}`">
				{{ model.AttractionMetaTitle }}
			</b-link>
			<label>&nbsp;{{ $t('id') }}&nbsp;{{ model.AttractionId }}</label>
		</template>

		<template #beforeFormActions>
			<google-map
				:latitude="+model.AttractionGoogleMapsGpsLat"
				:longitude="+model.AttractionGoogleMapsGpsLng"
				:zoom="+model.GoogleMapsZoom"
				:onChanged="coordinatesChanged"
				height="700"
			></google-map>
		</template>

		<template #customActions>
			<megau-button
				classprop="btn btn-success"
				icon="check"
				:title="$t('checklinksinthe')"
				@handleClick="checkLinks()"
			></megau-button>
		</template>

		<template #afterForm>
			<b-modal
				v-model="showNoteModal"
				:title="$t('resultoflinkche')"
				size="lg"
				no-close-on-backdrop
				centered
				content-class="links-modal"
			>
				<span>{{ noteText }}</span>
				<template v-slot:modal-footer="{}">
					<megau-button
						icon="check"
						:title="$t('ok2687')"
						@handleClick="showNoteModal = false"
						classprop="btn btn-success"
					></megau-button>
				</template>
			</b-modal> </template
		>>
	</detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import serviceDictionary from '@/services/service/dictionary.service';
import auth from '@/services/auth.service';
import enums from '@/services/helpers/enums';
import http from '@/services/helpers/http';
import apiCommon from '@/services/api/common.api';
import serviceCommon from '@/services/service/common.service';

import { model, fields } from './attraction.form-data';
import { handleTextboxVisibility } from './attraction.service';

export default {
	components: {
		DetailPage,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'Attraction',
			routeParam: 'AttractionId',
			model,
			schema: {
				groups: fields,
			},
			noteText: '',
			showNoteModal: false,
		};
	},

	computed: {
		showDelete() {
			return !!this.detailEntityId && auth.IsAllowedForUser(enums.role.Admin);
		},
	},

	async mounted() {
		const toUpdate = await handleTextboxVisibility(this.model.CountryId, this.model);
		this.updateFormModel(toUpdate.modelUpdate);
		this.updateSchemaFields(toUpdate.schemaUpdate);
	},

	methods: {
		async loadModel(id = null) {
			const value = id || this.detailEntityId;
			const resp = await apiCommon.getEntity(value, this.controllerName);
			const model = this.transformDtoToModel(resp.data);
			const toUpdate = await handleTextboxVisibility(model.CountryId, model);
			this.updateSchemaFields(toUpdate.schemaUpdate);
			this.updateFormModel({ ...model, ...toUpdate.modelUpdate }, true);
		},

		async loadResources() {
			const attractionGroups = await serviceDictionary.getAttractionGroupEnum();
			const CountryValues = this.$store.state.country.data;

			this.updateSchemaFields({
				CountryId: {
					values: CountryValues,
					onChanged: async (model, newVal, oldVal, field) => {
						const toUpdate = await handleTextboxVisibility(newVal, model);
						this.updateFormModel({ TextSelector: newVal, ...toUpdate.modelUpdate });
						this.updateSchemaFields(toUpdate.schemaUpdate);
					},
				},
				TextSelector: {
					values: CountryValues,
					onChanged: async (model, newVal, oldVal, field) => {
						const toUpdate = await handleTextboxVisibility(newVal, model);
						this.updateFormModel(toUpdate.modelUpdate);
						this.updateSchemaFields(toUpdate.schemaUpdate);
					},
				},
				AgroupId: { values: attractionGroups.dropdownVfg },
			});
		},

		transformDtoToModel(dtoModel) {
			const updateSchema = {
				AttractionIsVerified: { user: dtoModel.VerifiedByUserObject, date: dtoModel.DateVerified },
				UseAttraction: { user: dtoModel.DeniedByUserObject, date: dtoModel.DontUseFromDate },
			};

			this.updateSchemaFields(updateSchema);

			return {
				...dtoModel,
				TextSelector: dtoModel.CountryId,
				AttractionGoogleMapsGpsLat: +dtoModel.AttractionGoogleMapsGpsLat,
				AttractionGoogleMapsGpsLng: +dtoModel.AttractionGoogleMapsGpsLng,
			};
		},

		coordinatesChanged(lat, lng, zoom, polygonPaths) {
			const modelUpdate = {};

			if (lat) {
				modelUpdate.AttractionGoogleMapsGpsLat = lat;
				modelUpdate.AttractionGoogleMapsGpsLng = lng;
			}
			if (zoom) {
				modelUpdate.GoogleMapsZoom = zoom;
			}

			this.updateFormModel(modelUpdate);
			this.$refs[detailPageRefName].validate();
		},

		openDeleteModal() {
			serviceCommon.openDeleteModal(this.model.AttractionId, this.$modal, this.deleteModel);
		},

		async checkLinks() {
			this.startLoading();
			const badLinksList = await http.post('LinksChecker/CheckBadLinksFromAttraction', this.model).then((res) => res.data);
			this.stopLoading();
			if (badLinksList.length) {
				this.noteText = badLinksList;
				this.showNoteModal = true;
			}
		},
	},
};
</script>
<style lang="scss">
.attraction-form {
	.field-html-text-editor {
		.ck-content {
			width: 660px !important; // nastavene podla sirky clanku na portali
		}
		.ck-sticky-panel__content_sticky,
		.ck-sticky-panel__content {
			width: 660px !important; // nastavene podla sirky clanku na portali
		}
	}
}
.links-modal {
	.modal-body {
		white-space: pre-line;
	}
}
</style>
