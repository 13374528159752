import vueFormGenerator from 'vue-form-generator';
import validator from './attraction.validators';
import enums from '@/services/helpers/enums';

export const model = {
	AttractionId: 0,
	AttractionName: null,
	AttractionMetaTitle: null,
	AttractionUrlName: null,
	AttractionDomainGroupUrl: null,
	DomainUrl: null,
	AttractionStreet: '',
	CityId: null,
	AttractionWeb: '',
	AttractionPhone: '',
	AttractionEmail: '',
	AttractionTextSk: '',
	AttractionTextCz: '',
	AttractionTextPl: '',
	AttractionTextDe: '',
	AttractionTextEn: '',
	AttractionTextHu: '',
	AttractionTextRu: '',
	AttractionGoogleMapsGpsLat: null,
	AttractionGoogleMapsGpsLng: null,
	AgroupId: null,
	AttractionIsVerified: false,
	CountryId: null,
	AttractionOrder: 0,
	AttractionIsVisibleHomepage: false,
	GoogleMapsZoom: null,
	ImageFile: '',
	TextSelector: enums.country.SK,
	UseAttraction: true,
	DontUseSetByUser: null,
	DontUseFromDate: null,
	DeniedByUserObject: null,
};

export const fields = [
	{
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'AttractionName',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'htitle2675',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AttractionUrlName',
				required: true,
				validateDebounceTime: 500,
				validator: validator.validatePageUrl,
				i18n: {
					label: 'url2672',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AttractionMetaTitle',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'metatitle2673',
				},
			},
			{
				type: 'select',
				model: 'AgroupId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					noneSelectedText: '',
					value: 'AgroupId',
					name: 'AgroupNameSk',
				},
				i18n: {
					label: 'group2671',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'select',
				model: 'CountryId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					noneSelectedText: '',
					value: 'Id',
					name: 'Name',
				},
				i18n: {
					label: 'country:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AttractionStreet',
				i18n: {
					label: 'street',
				},
			},
			{
				type: 'autocomplete',
				typeSearch: '1',
				model: 'CityId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				i18n: {
					label: 'city2709',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AttractionWeb',
				i18n: {
					label: 'webpage',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AttractionPhone',
				i18n: {
					label: 'phone:',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'AttractionEmail',
				i18n: {
					label: 'e-mail:',
				},
			},
			{
				type: 'verified-switch',
				model: 'AttractionIsVerified',
				user: '',
				date: null,
				i18n: {
					label: 'attractionische',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'verified-switch',
				model: 'UseAttraction',
				user: '',
				date: null,
				i18n: {
					label: 'showattraction3254',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'switch',
				model: 'AttractionIsVisibleHomepage',
				i18n: {
					label: 'showonhomepage',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'AttractionOrder',
				i18n: {
					label: 'positiononhomep',
				},
			},
			{
				type: 'radios',
				model: 'TextSelector',
				i18n: {
					label: 'languagetransla',
				},
				values: [],
				radiosOptions: {
					value: 'Id',
					name: 'LanguageCode',
				},
				styleClasses: 'inline-radio-list',
			},
			{
				type: 'html-text-editor',
				model: 'AttractionTextSk',
				i18n: {
					label: 'languagetransla2714',
				},
				visible: false,
			},
			{
				type: 'html-text-editor',
				model: 'AttractionTextCz',
				i18n: {
					label: 'languagetransla2714',
				},
				visible: false,
			},
			{
				type: 'html-text-editor',
				model: 'AttractionTextEn',
				i18n: {
					label: 'languagetransla2714',
				},
				visible: false,
			},
			{
				type: 'html-text-editor',
				model: 'AttractionTextPl',
				i18n: {
					label: 'languagetransla2714',
				},
				visible: false,
			},
			{
				type: 'html-text-editor',
				model: 'AttractionTextHu',
				i18n: {
					label: 'languagetransla2714',
				},
				visible: false,
			},
			{
				type: 'html-text-editor',
				model: 'AttractionTextRu',
				i18n: {
					label: 'languagetransla2714',
				},
				visible: false,
			},
			{
				type: 'html-text-editor',
				model: 'AttractionTextDe',
				i18n: {
					label: 'languagetransla2714',
				},
				visible: false,
			},
			{
				type: 'file-select',
				model: 'ImageFile',
				required: true,
				validator: vueFormGenerator.validators.string,
				min: 1,
				i18n: {
					label: 'picture',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'AttractionGoogleMapsGpsLat',
				i18n: {
					label: 'latitude',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'AttractionGoogleMapsGpsLng',
				i18n: {
					label: 'longitude',
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'GoogleMapsZoom',
				required: true,
				validator: vueFormGenerator.validators.integer,
				min: 1,
				i18n: {
					label: 'zoomonmap',
				},
			},
		],
	},
];
