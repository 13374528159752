var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoaded
    ? _c(
        "detail-page",
        {
          ref: _vm.detailPageRefName,
          attrs: {
            formModel: _vm.model,
            formSchema: _vm.schema,
            tooltipMsg: "manualforattrac3309",
            showActionDelete: _vm.showDelete,
          },
          on: {
            saveForm: function ($event) {
              return _vm.saveModel($event)
            },
            closeDetail: function ($event) {
              return _vm.redirectToTable()
            },
            delete: function ($event) {
              return _vm.openDeleteModal()
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _vm._v(
                      "\n\t\t" + _vm._s(_vm.$t("detailofattract")) + "\n\t\t"
                    ),
                    _c(
                      "b-link",
                      {
                        attrs: {
                          target: "_blank",
                          href:
                            "" +
                            (_vm.model.AttractionDomainGroupUrl +
                              _vm.model.AttractionUrlName),
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t" +
                            _vm._s(_vm.model.AttractionMetaTitle) +
                            "\n\t\t"
                        ),
                      ]
                    ),
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("id")) +
                          " " +
                          _vm._s(_vm.model.AttractionId)
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "beforeFormActions",
                fn: function () {
                  return [
                    _c("google-map", {
                      attrs: {
                        latitude: +_vm.model.AttractionGoogleMapsGpsLat,
                        longitude: +_vm.model.AttractionGoogleMapsGpsLng,
                        zoom: +_vm.model.GoogleMapsZoom,
                        onChanged: _vm.coordinatesChanged,
                        height: "700",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "customActions",
                fn: function () {
                  return [
                    _c("megau-button", {
                      attrs: {
                        classprop: "btn btn-success",
                        icon: "check",
                        title: _vm.$t("checklinksinthe"),
                      },
                      on: {
                        handleClick: function ($event) {
                          return _vm.checkLinks()
                        },
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "afterForm",
                fn: function () {
                  return [
                    _c(
                      "b-modal",
                      {
                        attrs: {
                          title: _vm.$t("resultoflinkche"),
                          size: "lg",
                          "no-close-on-backdrop": "",
                          centered: "",
                          "content-class": "links-modal",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "modal-footer",
                              fn: function (ref) {
                                return [
                                  _c("megau-button", {
                                    attrs: {
                                      icon: "check",
                                      title: _vm.$t("ok2687"),
                                      classprop: "btn btn-success",
                                    },
                                    on: {
                                      handleClick: function ($event) {
                                        _vm.showNoteModal = false
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3698071577
                        ),
                        model: {
                          value: _vm.showNoteModal,
                          callback: function ($$v) {
                            _vm.showNoteModal = $$v
                          },
                          expression: "showNoteModal",
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.noteText))])]
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            3720672751
          ),
        },
        [_vm._v(">\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }