import validatorCommon from '../../services/helpers/validator.common';
import i18n from '../../services/lang';
import http from '../../services/helpers/http';

const validators = {
	async validatePageUrl(value, required, model) {
		let res = validatorCommon.checkEmpty(value, required);
		if (res) return res;

		let regex = /[À-ž\s]/;
		if (regex.test(value)) {
			console.log(value);
			return [i18n.t('amistake!')];
		}

		let existUrl = await http.get('Attraction/CheckExistsUrl', {
			attractionId: model.AttractionId,
			url: value,
		});

		if (existUrl.data) {
			return [i18n.t('urlnamemustbeor')];
		}

		return null;
	},
};

export default validators;
